module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Gabriel Margoses - Full-Stack Developer', // Navigation and Site Title
  titleAlt: 'Gatsby Prismic.io', // Title for JSONLD
  description: 'Gabriel Margoses - Personal Website',
  headline: 'Gabriel Margoses - Full-Stack Developer', // Headline for schema.org JSONLD
  url: 'https://margoses.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/logos/logo', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Margoses', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Gabo Goses', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitter: '@GaboGoses', // Twitter Username
  facebook: 'gilligoses', // Facebook Username
  googleAnalyticsID: 'UA-131569468-1',

  skipNavId: 'reach-skip-nav' // ID for the "Skip to content" a11y feature
};
